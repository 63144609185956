h2 {
  padding-bottom: 50px;
  padding-top: 20px;
}

.newFeatureAltert {
  padding-bottom: 50px;
}

.citationBox {
  padding-bottom: 50px;
}